<template>
<v-dialog v-model="showModal" persistent width="500">
    <div class="admin-modal">
      <h1 class="modal-title mb-5">{{ header }}</h1>

      <v-text-field  solo hide-details placeholder="20000" type="number" v-model="depositAmount">

      </v-text-field>
      <h6 style="color: red">* <span class="modal-subtext"> Your deposit would reflect within 24hrs. Thanks for your patience.</span></h6>
      <div class="dialog-btn mt-7 tw-justify-center tw-w-full tw-flex lg:tw-justify-start">
        <v-btn dark :color="color" depressed class="btn-no mr-4 btn-generic-style" @click="$emit('closeModal')">Cancel</v-btn>
        <v-btn :style="{color: color,
                backgroundColor: 'var(--white)',
                border: '1px solid'+color}" depressed class="btn-generic-style" @click="$emit('action',depositAmount)">Deposit</v-btn>

      </div>
    </div>
</v-dialog>
</template>

<script>
export default {
  name: "DepositModal",
  props:{
    color :{
      type : [String],
      default : "#004AAD"
    },
    header:{
      type: [String],
      default: "Enter Deposit Amount"
    },
    showModal :{
      type : [Boolean],
      default : false
    }
  },
  data(){
    return{
      depositAmount : ""
    }
  }
}
</script>

<style scoped lang="scss">
.admin-modal {
  margin: auto;
  background: var(--white);
  border-radius: 10px;
  padding: 30px;
}

.modal-title {
  font-family: var(--fontInter);
  text-align: center;
  color: var(--brown);
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;
}
.modal-subtext {
  font-family: var(--fontInter);
  text-align: center;
  color: var(--brown);
  font-size: 13px;
  font-weight: 300;
  line-height: 24px;
}
.btn-generic-style {
  width: 100px;
  height: 40px;
  border-radius: 10px;
  font-family: var(--fontInter);
  font-size: 14px;
}
.restrict-btn {
  color: var(--primaryColor);
  background-color: var(--white);
  border: 1px solid var(--primaryColor);
}

::v-deep .v-dialog {
  box-shadow: none !important;
  display: flex;
  justify-content: center !important;

}
</style>